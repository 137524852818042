#survey {
    line-height: 30px;
    background: #ffffff;

    .container-fluid {
        padding: 0;
    }

    .survey-description {
        padding: 2.5rem 0;
        text-align: center;
        background: #e5e5e5;

        p {
            margin-bottom: 0;
        }
    }

    #survey-form {
        padding-bottom: 3rem;
    }

    #survey-form div:first-child hr {
        visibility: hidden;
    }

    #survey-form div hr {
        width: 90%;
    }

    .center-input {
        width: max-content;
        margin: 0 auto;
    }

    input[type="radio"], input[type="checkbox"] {
        margin-top: 0;
        vertical-align: middle;
        height: 100%;
      }
}

