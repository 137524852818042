@import "~bootstrap/scss/bootstrap";
@import "~billboard.js/src/scss/billboard.scss";

@import "variables";
@import "header";
@import "footer";
@import "layout";
@import "home";
@import "App";

@import "projectDetails";
@import "dashboard";
@import "login";
@import "charts";
@import "tooltips";
@import "investment";
@import "kpi";
@import "open-feed";
@import "cardTable";


@import "valutazione";
@import "valutazioneheader";
@import "progetti";
@import "survey";
@import "404";
@import "usersManagement";
@import "thanks";
@import "faq";

@import "primereact";