table.investment-table {
  width: 100%;
  th,
  td {
    padding: 0.8em 0.6em;
    // text-align: right;
    // &.value-label {
    //   text-align: left;
    // }
  }
  th {
    background-color: #1c2743;
    color: #fff;
  }
  td {
    border-bottom: 1px solid #dbe4ec;
  }
  tr.total {
    font-weight: bold;

    td {
      border-bottom: none;
    }
  }
  .value-capex,
  .value-label {
    div.tooltipInfoBox {
      transform: translate(0px, 0px) !important;
    }
  }
  .value-locale,
  .value-vs {
    div.tooltipInfoBox {
      transform: translate(-90%, 0px) !important;
    }
  }
}

.investment-infobox {
  div {
    padding: 1.5em 0;
  }
  &.va-number {
    h2 {
      font-size: 3em;
      font-weight: normal;
    }
    span.va-desc {
      color: #666;
    }
  }
}
