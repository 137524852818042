.card-table {
  .card-title {
    margin-bottom: 1em;
  }
}

.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}
