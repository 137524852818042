.border-tab {
  border-right: 2px solid $third-color;
}

.user-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 11rem;
  font-size: 1.2rem;
}

.create-edit-user-button {
  border-color: $second-color;
}

.delete-user-button {
  border-color: $red;
}

#administration.megaContent {
  @media (min-width: 1600px) { 
    padding-top: 3.5rem;
  }

  @media (min-width: 1600px) { 
    padding-left: 15rem;
    padding-right: 15rem;
  }

  @media (min-width: 1600px) { 
    padding-top: 5rem;
  }

  @media (min-width: 4096px) { 
    padding-top: 8rem;
  }
}

.nav-admin {
  .nav-item a {
    color: inherit;
  }

  .nav-item .nav-pills .nav-link-active {
    background-color: $second-color !important;
  }
}