:root {
  scrollbar-color: #1c2743 transparent;
}

#valutazione {
  background: $primary-lightest;
  line-height: 34.5px;

  .btn {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .btn-nav {
    background-color: $third-color;
    border-color: $third-color;
    color: #ffffff;
  }

  .btn-nav .active {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .nav {
    color: $primary-lighter;
    .active {
      color: $first-color;
    }
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    table-layout: fixed;
    width: 100%;
  }
  table tbody {
    display: table;
    width: 100%;
  }

  table thead {
    display: table;
    width: 100%;
  }

  a {
    color: $primary-light;
    &:hover,
    &:visited {
      color: $primary-light;
    }
  }

  .main-wrapper {
    width: 100%; //width: 70vw;

    overflow-x: auto;

    box-sizing: border-box;
    display: table;
    table-layout: fixed;
    margin-bottom: 2rem;
  }

  .impact {
    .chartBox {
      //padding: 1em 3em;
      text-align: center;
      button {
        background-color: $first-color;
        border-color: $first-color;
      }
      h5 {
        text-align: center;
        // margin-top: .5em;
      }
      &.impactChart {
        //min-width: 210px;
        //width: 16.65%;
        min-width: 11rem;
        width: 20%;
        max-width: 180px;
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }

  #submitted-button {
    background-color: inherit;
    color: inherit;
    opacity: 1;
    width: fit-content;
  }
}

#statusbar {
  .simple-box {
    color: #ffffff;
    font-weight: bold;
  }
  .success {
    background-color: #c2c923;
  }

  .error {
    background-color: #cb1b4a;
  }

  .elaborating {
    background-color: #08a2aa;
  }
}

#template-upload-form {
  .form-group {
    margin-bottom: 0px;
  }
}

.hr-subcategory {
  border-top: 1px solid;
}

.hr-question {
  border-top: 1px dotted;
}

.assessment-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 11rem;
  font-size: 1.2rem;
}

.add-aud-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: fit-content;
  font-size: 1.2rem;
}

.sticky-footer-button {
  position: sticky;
  bottom: 1.3rem;
  z-index: 1000;
  animation: fadeInTEST 0.3s;
  transition: opacity 0.4s;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
}

.accordion-arrow {
  width: 25px;
  height: 25px;
}

.accordion {
  display: flex;
  flex-direction: column-reverse;
  //cursor: pointer;
}

.accordion .accordion-title {
  cursor: pointer;

  /* .tooltip-inner {
    max-width: 300px !important;
  } */
}

.tooltip-maxWith .tooltip-inner {
  max-width: 300px !important;
}

h3 .accordion-arrow,
h5 .accordion-arrow {
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

div[class~='show'] + h3 .accordion-arrow,
div[class~='show'] + h5 .accordion-arrow {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

//scrollbar
/* width */
::-webkit-scrollbar {
  width: 12px;
}

// table::-webkit-scrollbar {
//   width: 7px;
// }

// table {
//   scrollbar-width: thin;
//   scrollbar-color: #1c2743 transparent;
// }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 1px;
}

table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c2743;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c2743;
}

.addon-for-number {
  background-color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .collapse {
  //pointer-events: none;
  cursor: inherit !important;
}

.form-group#select-sroi div {
  justify-content: center;
}

.addon-for-number-sroi.input-group-text {
  background-color: white;
  border: 0 !important;
}

.question-code {
  width: fit-content;
  -moz-border-radius: 5%;
  border-radius: 5%;
  border: solid 2px $third-color;
  color: $first-color;
  text-align: center;
  display: block;
  font-size: small;
  font-weight: 400;
  padding: 0 0.3rem;
}

.hide {
  display: none;
}

.fade-in-div {
  animation: fadeIn linear 500ms;
  -webkit-animation: fadeIn linear 500ms;
  -moz-animation: fadeIn linear 500ms;
  -o-animation: fadeIn linear 500ms;
  -ms-animation: fadeIn linear 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-div {
  animation: fadeOut linear 500ms;
  -webkit-animation: fadeOut linear 500ms;
  -moz-animation: fadeOut linear 500ms;
  -o-animation: fadeOut linear 500ms;
  -ms-animation: fadeOut linear 500ms;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scrollTop {
  position: fixed;
  width: auto;
  bottom: 1.3rem;
  //transform: translate(-50%, -50%);
  right: 5rem;
  align-items: center;
  height: 1.25rem;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeInTEST 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeInTEST {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.hide-number-arrows {
  input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
  }
}

table.sroi-table {
  width: 100%;

  th,
  td {
    padding: 0.8em 0.6em;
  }
  td {
    border-bottom: 1px solid #dbe4ec;
  }
  tr.total {
    font-weight: bold;

    td {
      border-bottom: none;
    }
  }
  .value-label {
    div.tooltipInfoBox {
      transform: translate(0px, 0px) !important;
    }
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background-color: #1c2743;
    color: #fff;
  }

  tbody tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    background-color: #fff;
  }

  thead th:first-child {
    left: 0;
    z-index: 10;
  }

  tbody tr th:first-child {
    left: 0;
    z-index: 10;
    min-width: 25rem;
    width: 25rem;
    border-bottom: 1px solid #dbe4ec !important;
  }
}

.th-width {
  width: 25rem;
  min-width: 25rem;
  max-width: 25rem;
}

.table-spacing {
  white-space: normal; /* Allow text to wrap onto multiple lines */
  word-break: break-word; /* Break words if they exceed the container width */
}

.cell-width {
  width: 22rem;
  min-width: 22rem;

  @media (min-width: 2236px) {
    width: 22rem;
    min-width: 22rem;
  }
}

.total-width {
  min-width: 17rem;

  @media (min-width: 2236px) {
    min-width: 22rem;
  }
}

input[type='radio'],
input[type='checkbox'] {
  margin-top: 0.563rem;
  //vertical-align: middle;
  //height: 100%;
}

#technical-people-div {
  margin-top: 8rem;
  margin-bottom: 2rem;

  .row {
    span.description {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 2rem;
      border-left: 13px solid #1c2743;
    }

    .form-label {
      width: max-content;
    }

    .form-group {
      max-width: max-content;
    }

    .form-group input {
      min-width: 10rem;
      max-width: 17rem;
      // min-width: 17rem;
      // max-width: 17rem;

      @media (min-width: 1600px) {
        min-width: 18rem;
        max-width: 18rem;
      }

      @media (min-width: 1920px) {
        min-width: inherit;
        max-width: 25rem;
      }
    }

    @media (min-width: 2236px) {
      grid-gap: 10rem;
      gap: 10rem;

      .form-label {
        width: inherit;
      }

      .form-group {
        max-width: inherit;
      }
    }
  }
}

#simple-sroi-div {
  margin-top: 7rem;

  .row {
    span.simple-sroi-span {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 2rem;
      border-left: 13px solid #1c2743;
    }
  }
}

.residual-life {
  background-color: #c2c92342;

  &:focus {
    background-color: #c2c92342;
  }
}

.simple-box.instruction {
  width: fit-content;
  margin: 0 auto;
  margin-top: 2.3rem;

  &.sroi {
    margin-bottom: 2.5rem;

    @media (min-width: 1920px) {
      max-width: 75%;
    }
  }
}

.fade-in-accordion.accordion .collapsing {
  transition: all 1s ease;
}

.instructionSection {
  column-count: 2;
  column-gap: 4rem;
  padding: 0 1rem;
}

.percentage-max-width {
  max-width: 5rem;
  text-align: right;
}

.col-05 {
  flex: 0 0 5%;
  max-width: 5%;
}

.question-line {
  padding: 0.8em 0.6em 0.8rem 0;
  border-left: 4px solid #1c2743;

  &.list-perc-question {
    height: 20rem;
  }

  &.other-question {
    height: 5rem;
  }
}

.m-hr {
  margin: 4rem 20rem;
}

.aud-project .m-hr {
  margin: 2rem 20rem;
  background-color: $third-color;
}

.aud-project .input-width {
  margin: auto;
  width: 17rem;
  min-width: 17rem;

  &.larger {
    width: 25rem;
    min-width: 25rem;
  }

  &.middle-select {
    width: 17rem;
    min-width: 17rem;
  }

  @media (max-width: 1366px) {
    width: 11rem;
    min-width: 11rem;
  }

  @media (min-width: 1367px) and (max-width: 1600px) {
    width: 15rem;
    min-width: 15rem;
  }

  .center-text__value-container {
    justify-content: center;
  }
  .center-text__indicators {
    position: absolute;
    right: 0;
    height: 100%;
  }
}

.aud-instruction-box {
  & > span {
    border-left: 13px solid $third-color;
  }
}

.please-note {
  p {
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $third-color;
    }
  }
}

.asterisk-nb {
  vertical-align: sub;
  color: $third-color;
  font-size: 2rem;
}

.dist-contract-alert {
  margin-top: 1rem;
  color: $red;
}

.state-loading-spinner {
  height: 100%;
  width: 100%;

  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
}

.loading-spinner-overlay {
  position: fixed;
  z-index: 98;
}

.loading-spinner-container {
  position: absolute;
  z-index: 99;
}

.loading-spinner-text {
  font-size: 1.4rem;
  font-weight: bolder;
  color: #1c2743;
}

.myInputs {
  .p-inputtext.p-invalid.p-component {
    border-color: rgb(206, 206, 58);
  }
}

.tutorial-button-container {
  position: fixed;
  bottom: 270px; /* Adjust as needed */
  right: 32px; /* Adjust as needed */
}
a:hover {
  text-decoration: none;
}

.tutorial-button {
  background-color: $second-color;
  text-decoration: none;
  border: none;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  padding: 5px 10px;
  overflow: hidden;
  transition: width 0.3s; /* Add a smooth transition effect */
  overflow-wrap: break-word;
  font-weight: 500;
  font-size: 1rem;
  .ico {
    path {
      fill: #ffffff;
    }
  }

  &.hovered {
    width: 320px;
    text-align: center;

    .button-text {
      animation: fadeIn 0.2s ease 0.2s forwards; /* Delay the appearance by 1 second */
    }
  }
  &.hovered-small {
    width: 250px;
    text-align: center;

    .button-text {
      animation: fadeIn 0.2s ease 0.2s forwards; /* Delay the appearance by 1 second */
    }
  }
  .button-text {
    opacity: 0;
    display: none;
    margin-left: 5px;
    color: #ffffff;
    line-height: 20px;
  }

  .show-text {
    display: block;
    text-decoration: none;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.tutorial-button-thanks {
  background-color: $third-color !important;
  border-color: none !important;
  width: 50px;
  height: 50px;
}
.tutorial-button-header {
  background-color: $first-color;
  width: 50px;
  height: 50px;
}

#video-player {
  position: relative;
  height: 325px;
  width: 500px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid black;
  -webkit-box-shadow: 5px 6px 23px -2px rgba(56, 56, 56, 0.88);
  box-shadow: 5px 6px 23px -2px rgba(56, 56, 56, 0.88);
  button {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: transparent !important;
  }
  h4 {
    text-align: left !important;
    line-height: normal !important;
    font-size: 1.5rem;
    font-weight: 500;
  }
}
