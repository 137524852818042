#economic-kpi,
#standard-kpi {
  background: $primary-lightest;
  .kpi-table {
    @extend .mt-4;
    tr.sub-table-title {
      td {
        border-top: 2px solid $second-color;
      }
      @extend .text-left;
      svg {
        path {
          fill: $second-color;
        }
      }
      .value-label {
        font-weight: $font-w-semibold;
        &.desc {
          svg {
            path {
              fill: rgb(196, 196, 196);
            }
          }
        }
      }
      &:first-child {
        td {
          border-top: 0px;
        }
      }
    }
  }
  .btn-toolbar {
    margin: 0px 20px 0px;
  }
  .list {
    .content-card {
      @extend .col-6;
      @extend .col-lg-3;
      @extend .col-md-4;
      @extend .d-flex;
      .card {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: $primary-lightest;
        border: 0px;
        width: 100%;
        @extend .align-items-center;
        .card-img-top {
          width: 110px;
        }
        .card-body {
          width: 100%;
          font-size: $font-small-1;
          .card-title {
            @extend .py-3;
            font-size: $font-lg;
            font-weight: $font-w-bold;
            text-transform: uppercase;
            @extend .text-center;
            span {
              display: block;
              font-size: $font-big;

              &[data-trans] {
                display: inline;
                span {
                  display: inline;
                }
                .tooltipInfoBox {
                  text-transform: initial;
                }
              }
            }
          }
          h6 {
            font-size: $font-xsmall-2;
            color: $primary-light;
            font-weight: $font-w-regular;
            text-transform: uppercase;
          }
          .link-list {
            @extend .d-flex;
            @extend .flex-wrap;
            @extend .align-items-center;
            @extend .justify-content-between;
            color: $primary;
            font-weight: $font-w-medium;
            a {
              color: $primary;
            }
          }
        }
      }
      &.paused {
        .card-img-top {
          -webkit-filter: grayscale(1);
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          filter: gray;
          filter: grayscale(100%);
          filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
        }
        .card-title {
          @extend .text-center;
          color: $primary-light;
        }
      }
    }
  }
}
