// font family
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
$font-primary: "Barlow", sans-serif;
$font-primary-condensed: "Barlow Condensed", sans-serif;

//font weight size
$font-w-extralight: 100;
$font-w-superlight: 200;
$font-w-light: 300;
$font-w-regular: 400;
$font-w-medium: 500;
$font-w-semibold: 600;
$font-w-bold: 700;
$font-w-xbold: 800;
$font-w-black: 900;

//font size

/* 16px body*/
$font-base: 1.20rem;
$font-xsmall: 0.6875rem;
$font-xsmall-1: 0.75rem;
$font-xsmall-2: 0.8125rem;
$font-small: 0.875rem;
$font-small-1: 0.9375rem;
$font-big: 1.125rem;
$font-medium: 1.5rem;
$font-lg: 1.5rem;
$font-lg-2: 2.1rem;
$font-lg-3: 2.25rem;
$font-xxl: 3rem;
$font-xlg-2: 4rem;
$font-line-lg: 1.8rem;
$font-line-medium: 1.575rem;
$font-line-xxl: 3.6rem;

/*
$font-base: 20px;
$font-xsmall: 11px;
$font-xsmall-1: 12px;
$font-xsmall-2: 13px;
$font-small:14px;
$font-small-1:15px;
$font-big: 18px;
$font-medium:21px;
$font-lg: 24px;
$font-lg1: 26px;
$font-lg-2:28px;
$font-lg-3:36px;
$font-xxl: 48px;
$font-xlg-2: 64px;
*/

//line-heigt
$font-line-lg: 28.8px;
$font-line-big: 21.6px;
$font-line-medium: 25.2px;
$font-line-xxl: 57.6px;
$font-line-lg-3: 43.3px;
$line-height-base: 19.2px;

//colors
$primary: #000000;
$primary-light: lighten($primary, 40); // #666666
$primary-light-saturate: lighten(
  saturate(adjust-hue($primary, 213), 14.04),
  53.92
); //##79889A
$primary-lighter: lighten($primary, 76.86); //#C4C4C4
$primary-lightest: lighten($primary, 89.8); //#E5E5E5
$primary-lightest-1: lighten($primary, 95.29); //#f3f3f3
$primary-lightest-saturate: lighten(
  saturate(adjust-hue($primary, 208), 30.91),
  89.22
); //#DBE4EC

$light-primary: #ffffff;

$first-color: #1c2743; //blue
$first-color-darken: darken(
  desaturate(adjust-hue($first-color, -2), 16.05),
  1.37
); //#212837
$second-color: #08a2aa; //aqua
$third-color: #c2c923; //acid green

$green: #689689;
$yellow: #D4BE31;
$red: #C94723;