.faq-section {
   padding: 0;

   @media (min-width: 1280px) { 
      padding: 0 2rem;
   }

   @media (min-width: 1600px) { 
      padding: 0 4rem;
   }

   @media (min-width: 1920px) { 
      padding: 0 5rem;
   }

   @media (min-width: 2236px) { 
      padding: 0 7rem;
   }

   @media (min-width: 3840px) { 
      padding: 0 13rem;
   }
}

.title-faq-section {
   margin: 3rem 0;
   text-align: center;
   text-transform: none;
   font-size: 2rem;
   font-weight: normal;
}

.section-title {
   text-transform: uppercase;
   color: $second-color;
   font-size: 2rem;
}

.faq.accordion-arrow {
   path {
      fill: $second-color;
   }
}

.faq-text-format div {
   line-height: 1.6rem;
   text-align: justify;
   padding: 0 3rem 0 2rem;
}