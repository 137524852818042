div.tooltipInfoBox {
  background-color: $primary-light-saturate;
  color: #f9f9f9;
  position: absolute;
  top: 104%;
  left: 0;
  width: 20vw;
  transform: translate(-30%, 0);
  margin-bottom: 15px;
  // text-align: center;
  font-size: 14px;
  z-index: 999;
  padding: 0.8em !important;
  border-radius: 0.5em;
  line-height: normal;
  font-weight: initial;
  font-family: $font-primary-condensed;
  text-align: initial;
  text-transform: initial;
}
